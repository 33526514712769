<template lang="pug">
div
  .costs-list-subtitle.m-t-20
      strong {{ $t("financeForms.downPayment") }}:
  .preloader-behind
    .preloader-behind-row.costs-row.costs-row--downpayment.m-t-15
      div(v-for="(downPayment, index) in downPayments" :key="index")
        input.radio-block-input(
          type="radio"
          v-validate="'required'"
          :checked="downPayment.monthly_cost === currentMonthlyPrice"
          :id="`downPayment${index}`"
          :value="downPayment.value"
          @change.stop="setDownPaymentId(downPayment)"
        )
        label.radio-block(:for="`downPayment${index}`")
          .radio-block-title
            strong {{formatPrice(downPayment.value)}} {{ currencySymbol }}
          div(v-if="showPrices")
            .radio-block-info(v-if="downPayment.value === vehicle.nonRefundableCashPayment") {{ $t("steps.costs.standard") }}
            .radio-block-info.red-text(v-else)
              | {{ formatPriceNegative(downPayment.monthly_cost - selectedDistance.monthly_cost) }} {{ currencySymbol }}/{{ $t('mon') }}



    small.text-danger {{ errors.first('getDefaults[getPriceForm][distance]:required') }}
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { formatPrice, formatPriceNegative } from '@/helpers/helpers.js';

export default {
  props: {
    downPayments: Array,
    selectedDistance: Object,
    showPrices: Boolean,
  },
  computed: {
    ...mapGetters('product', [ 'getDefaults', 'getPriceForm']),
    ...mapState('product', [ 'vehicle', 'currentMonthlyPrice']),
    ...mapState('reseller', {
      customStyle: state => state.customStyle.firstStep || {},
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
  },
  methods: {
    formatPrice,
    formatPriceNegative,
    ...mapActions('product', ['getFixedCostByMonthAndDistance', 'updateDefaults', ]),
    setDownPaymentId(downPayment) {
      const _this = this;

      this.getFixedCostByMonthAndDistance([this.getDefaults[this.getPriceForm]['months'], this.getDefaults[this.getPriceForm]['distance']])
          .then(() => {
            this.$store.dispatch('order/saveAllFields', {'vehicleCostDownPaymentId': downPayment.id})

            this.$store.dispatch('order/saveAllFields', {'totalMonthlyPrice': downPayment.monthly_cost})

            this.$store.dispatch('product/setCurrentMonthlyPrice', downPayment.monthly_cost)

            _this.updateDefaults({
              form: _this.getPriceForm,
              downPayment: downPayment.value,
              price: downPayment.monthly_cost
            })
          })
    },
  },
}
</script>